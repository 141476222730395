export function isValidPhoneNumber(value: string): boolean {
  const mobileNumberPattern = /^\+([ ()-]*[0-9]){8}([ ()-]*[0-9][ ()-]*)*$/;
  const trimmedValue = value.trim().split(" ").join("");

  return (
    trimmedValue.length >= 13 &&
    trimmedValue.length <= 15 &&
    mobileNumberPattern.test(trimmedValue)
  );
}
